  .coin-heads, .coin-tails {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    backface-visibility: hidden;
  }

  .flipping .coin-heads, .flipping .coin-tails {
    animation: flip 2s infinite;
  }
  
  @keyframes flip {
    0% {
      transform: rotateX(0deg);
    }
    50% {
      transform: rotateX(90deg);
    }
    100% {
      transform: rotateX(180);
    }
  }
  
  .coin {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    backface-visibility: hidden;
    perspective: 1000px;
  }
  
  .coin.flipping {
    animation: flip 2s infinite;
  }