/* RollDice.css */
.dice {
    width: 100px;
    height: 100px;
    background-color: white;
    border: 2px solid #333;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 10px;
    border-radius: 8px;
    transition: transform 0.3s ease-in-out;
  }
  
  .dice.rolling {
    animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
  }
  
  @keyframes shake {
    0%, 100% {
      transform: translate(0, 0);
    }
    10%, 30%, 50%, 70%, 90% {
      transform: translate(-1px, -1px);
    }
    20%, 40%, 60%, 80% {
      transform: translate(1px, 1px);
    }
  }

  .dice-dots {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .dot {
    background-color: #ffffff;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    position: absolute;
  }
  
  .dot-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .dot-top-left {
    top: 15%;
    left: 15%;
  }
  
  .dot-top-right {
    top: 15%;
    right: 15%;
  }
  
  .dot-bottom-left {
    bottom: 15%;
    left: 15%;
  }
  
  .dot-bottom-right {
    bottom: 15%;
    right: 15%;
  }
  
  .dot-mid-left {
    top: 50%;
    left: 15%;
    transform: translateY(-50%);
  }
  
  .dot-mid-right {
    top: 50%;
    right: 15%;
    transform: translateY(-50%);
  }