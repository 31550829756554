.faq-container {
    margin: 0 auto;
    padding: 20px;
  }
  
  .faq-list {
    list-style-type: none;
    padding: 0;
  }
  
  .faq-item {
    margin-bottom: 10px;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
  }
  
  .faq-answer {
    padding: 10px;
    background: #f9f9f9;
    border-left: 2px solid #ddd;
  }
  
  .icon {
    display: flex;
    align-items: center;
  }